//rfce - react functional component with export
import { useState, useEffect } from 'react'

//import plot from '../../src/indicators.html'

function Analytics() {
  /* LEFT PANE / SIDE BAR
   */
  /*
  Radio Buttons; analyticsService
  - Example:https://dev.to/collegewap/how-to-work-with-radio-buttons-in-react-3e0o
  - defaults to empty string
  */

  const [table2Get, setTable2Get] = useState('')
  const [userDataSet, setUserDataset] = useState('')

  const [dataAnalyticsAction, setDataAnalyticsAction] = useState('')

  const onDataAnalyticsActionChange = (e) => {
    setDataAnalyticsAction(e.target.value)
  }

  const [dataToolsAction, setDataToolsAction] = useState('')

  const onDataToolsActionChange = (e) => {
    setDataToolsAction(e.target.value)
  }

  const [aiAnalyticsAction, setAiAnalyticsAction] = useState('')

  const onAiAnalyticsServiceChange = (e) => {
    setAiAnalyticsAction(e.target.value)
  }

  /* RIGHT PANE VARIABLES/Functions
   */

  /********** LOAD NEW DATA ***************
   + loadNewDataClick ... called on button clicked
     +- setLoadDataState.click State
     +- triggers useEffect when loadNewDataState changes
   + loadNewData(): function to load data to cloud
   + useEffect triggered on setLoadDataState change
 
   + NOTE - other functions work in the same way
     +- getDataSet, transformData, forecast
  */

  const loadNewDataClick = () => {
    setTable2Get('onlinesales_w_prodinfo_filtered')
    setUserDataset('algobizai_tst')
    setLoadNewDataState({ click: true, loaded: false })
  }

  const [loadNewDataState, setLoadNewDataState] = useState({
    click: false,
    loaded: false,
  })

  const [newData, setNewData] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  /* fetch params
     - https://stackoverflow.com/questions/37230555/get-with-query-string-with-fetch-in-react-native
  */
  const loadNewData = () => {
    console.log('userDataSet = ', userDataSet)
    console.log('table2Get = ', table2Get)

    fetch(
      `/api/load_data?user_dataset=${encodeURIComponent(
        userDataSet,
      )}&table=${encodeURIComponent(table2Get)}`,
      {
        method: 'Get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setNewData({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
  }

  /****** GET EXISTING DATASET *********/

  const [getDataSetState, setGetDataSetState] = useState({
    click: false,
    loaded: false,
  })

  const getDataSetClick = () => {
    setTable2Get('onlinesales_w_prodinfo_filtered')
    setUserDataset('algobizai_tst')

    setGetDataSetState({ click: true, loaded: false })
  }

  const [dataSet, setDataSet] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  const getDataSet = () => {
    console.log(aiAnalyticsAction)

    console.log('userDataSet = ', userDataSet)
    console.log('table2Get = ', table2Get)

    fetch(
      `/api/get_data?user_dataset=${encodeURIComponent(
        userDataSet,
      )}&table=${encodeURIComponent(table2Get)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setDataSet({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
  }

  /****** TRANSFORM DATA *********/
  const [transform, setTransform] = useState({
    click: false,
    transformed: false,
  })

  const transformClick = () => setTransform({ click: true, transformed: false })

  const [dataTransform, setDataTransform] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })
  const transformData = () => {
    console.log('transform data')

    const params = {
      user_dataset: 'algobizai_tst',
    }

    const transform_dict = {
      transform_type: 'bq_pivot_unstack',
      from_table: 'onlinesales_w_prodinfo_filtered',
      target_table: 'trfm_unstack_onlinesales_week_uk_eire_nld',
      ts_date_col: 'InvoiceDate',
      ts_data_col: 'Quantity',
      ts_period: 'Week',
      pivot_col: 'Country',
      pivot_col_filter_list: ['United Kingdom', 'EIRE', 'Netherlands'],
    }
    const body = JSON.stringify(transform_dict)
    //console.log('hello')
    fetch(
      `/api/transform?user_dataset=${encodeURIComponent(params.user_dataset)}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      },
    ).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setDataTransform({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
    //console.log(data)
  }

  /****** FORECAST *********/

  const [forecast, setForecast] = useState({ click: false, forecasted: false })

  const forecastClick = () => setForecast({ click: true, forecasted: false })

  const [dataForecast, setdataForecast] = useState({
    name: '',
    date: '',
    environment: '',
    port: '',
    html: '',
  })

  /* get html figure
  //https://stackoverflow.com/questions/67851729/render-html-div-in-react
  */

  const getForecast = () => {
    console.log('forecast')

    const params = {
      user_dataset: 'algobizai_tst',
    }

    const forecast_dict = {
      forecast_type: 'multivar',
      from_table: 'onlinesales_w_prodinfo_filtered',
      model_name: 'mdl_forecast_onlinesales_multivar_uk_eire_nld',
      forecast_data_col: 'Quantity',
      forecast_period: 'Week',
      ts_id_col: 'Country',
      ts_id_list: ['United Kingdom', 'EIRE', 'Netherlands'],
      forecast_table: 'forecast_onlinesales_multivar_uk_eire_nld',
      forecast_adj_table: 'forecast_adj_onlinesales_multivar_uk_eire_nld',
      forecast_horizon: 4,
      forecast_lower_bound: 0,
      unstacked_from_table: 'trfm_unstack_onlinesales_week_uk_eire_nld',
      ts_date_col: 'InvoiceDate',
    }

    const body = JSON.stringify(forecast_dict)
    //console.log('hello')
    fetch(
      `/api/forecast?user_dataset=${encodeURIComponent(params.user_dataset)}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      },
    ).then((res) =>
      res.json().then((data) => {
        // Setting a data from api
        //console.log(data)
        setdataForecast({
          name: data.Name,
          date: data.Date,
          environment: data.Environment,
          port: data.Port,
          html: data.html,
        })
      }),
    )
    //console.log(data)
  }

  /****** USE EFFECT ********
     - load new Data
     - get dataset
     - transform data
     - forecast
    */

  useEffect(() => {
    if (loadNewDataState.click === true) {
      loadNewData()
      setLoadNewDataState({ click: false, loaded: true })
    }

    if (getDataSetState.click === true) {
      getDataSet()
      setGetDataSetState({ click: false, loaded: true })
    }

    if (transform.click === true) {
      console.log('transform = ', transform)
      transformData()
      setTransform({ click: false, transformed: true })
    }

    if (forecast.click === true) {
      console.log('forecast = ', forecast)
      getForecast()
      setForecast({ click: false, forecasted: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadNewDataState, getDataSetState, transform, forecast])

  return (
    <>
      {/**********LEFT PANE****************/}
      <div className="splitLeftContainer">
        <input
          type="radio"
          name="dataAnalyticsAction"
          value="loadData"
          id="loadData"
          checked={dataAnalyticsAction === 'loadData'}
          onChange={onDataAnalyticsActionChange}
        />
        <label htmlFor="loadData">Load Data</label>
        <br />
        <input
          type="radio"
          name="dataAnalyticsAction"
          value="dataTools"
          id="dataTools"
          checked={dataAnalyticsAction === 'dataTools'}
          onChange={onDataAnalyticsActionChange}
        />
        <label htmlFor="dataTools">Data Tools</label>
        <br />
        {/* Left Pane, AI Analytics..dataTools */}
        {dataAnalyticsAction === 'dataTools' ? (
          <div className="analyticsSubActionDiv">
            <input
              type="radio"
              name="dataToolsAction"
              value="stitchData"
              id="stitchData"
              checked={dataToolsAction === 'stitchData'}
              onChange={onDataToolsActionChange}
            />
            <label htmlFor="dataToolsAction">Stitch Datasets</label>
            <br />
            <input
              type="radio"
              name="dataToolsAction"
              value="dataTransform"
              id="dataTransform"
              checked={dataToolsAction === 'dataTransform'}
              onChange={onDataToolsActionChange}
            />
            <label htmlFor="dataToolsAction">Data Transform</label>
            <br />
          </div>
        ) : null}
        <input
          type="radio"
          name="dataAnalyticsAction"
          value="aiAnalytics"
          id="aiAnalytics"
          checked={dataAnalyticsAction === 'aiAnalytics'}
          onChange={onDataAnalyticsActionChange}
        />
        <label htmlFor="aiAnalytics">AI and Analytics</label>
        <br />
        {/*Left pane, AI Analytics Actions */}
        {dataAnalyticsAction === 'aiAnalytics' ? (
          <div className="analyticsSubActionDiv">
            <input
              type="radio"
              name="aiAnalyticsService"
              value="salesAnalytics"
              id="salesAnalytics"
              checked={aiAnalyticsAction === 'salesAnalytics'}
              onChange={onAiAnalyticsServiceChange}
            />
            <label htmlFor="salesAnalytics">Sales Analytics</label>
            <br />
            <input
              type="radio"
              name="aiAnalyticsService"
              value="forecasting"
              id="forecasting"
              checked={aiAnalyticsAction === 'forecasting'}
              onChange={onAiAnalyticsServiceChange}
            />
            <label htmlFor="forecasting">Forecasting</label>
            <br />
            <input
              type="radio"
              name="aiAnalyticsService"
              value="segmentation"
              id="segmentation"
              checked={aiAnalyticsAction === 'segmentation'}
              onChange={onAiAnalyticsServiceChange}
            />
            <label htmlFor="segmentation">Segmententation</label>
            <br />
            <input
              type="radio"
              name="aiAnalyticsService"
              value="churnAnalytics"
              id="churnAnalytics"
              checked={aiAnalyticsAction === 'churnAnalytics'}
              onChange={onAiAnalyticsServiceChange}
            />
            <label htmlFor="churnAnalytics">Churn Analytics</label>
            <br />
            <input
              type="radio"
              name="aiAnalyticsService"
              value="pricingAnalytics"
              id="pricingAnalytics"
              checked={aiAnalyticsAction === 'pricingAnalytics'}
              onChange={onAiAnalyticsServiceChange}
            />
            <label htmlFor="pricingAnalytics">Pricing Analytics</label>
          </div>
        ) : null}{' '}
        {/* end data dataAnalyticsAction === aiAnalytics */}
      </div>{' '}
      {/*end Left Side Bar */}
      {/**********RIGHT PANE****************/}
      <div className="splitWrapper">
        <div className="splitRightContainer">
          {/* Right Pane, Load Data */}
          {dataAnalyticsAction === 'loadData' ? (
            <>
              <br />
              <br />
              <button className="button" onClick={loadNewDataClick}>
                Load New data
              </button>
              <br />
              <br />
              {loadNewDataState.loaded ? (
                <iframe
                  title="html plot"
                  srcdoc={newData.html[0]}
                  width="750px"
                  height="270px"
                  style={{ border: 'none' }}
                ></iframe>
              ) : null}
            </>
          ) : null}
          {/* Right Pane, Data Tools */}
          {dataAnalyticsAction === 'dataTools' ? (
            dataToolsAction === 'stitchData' ? (
              <>
                <h3> Stitch Datasets </h3>
                <p>tbd</p>
              </>
            ) : dataToolsAction === `dataTransform` ? (
              <>
                <h3> Data Transform</h3>
                <br />
                <br />
                <button className="button" onClick={getDataSetClick}>
                  Get Data Set
                </button>
                <br />
                <br />

                {getDataSetState.loaded ? (
                  <iframe
                    title="html plot"
                    srcdoc={dataSet.html[0]}
                    width="750px"
                    height="270px"
                    style={{ border: 'none' }}
                  ></iframe>
                ) : null}
                <br />
                <br />

                <button className="button" onClick={transformClick}>
                  Pivot - Unstack
                </button>
                <br />
                <br />
                {transform.transformed ? (
                  <iframe
                    title="html plot"
                    srcdoc={dataTransform.html[0]}
                    width="750px"
                    height="270px"
                    style={{ border: 'none' }}
                  ></iframe>
                ) : null}
              </>
            ) : null
          ) : null}

          {/* Right Pane, aiAnalytics */}
          {dataAnalyticsAction === 'aiAnalytics' ? (
            aiAnalyticsAction === 'salesAnalytics' ? (
              <h3>Sales Analytics</h3>
            ) : aiAnalyticsAction === 'forecasting' ? (
              <>
                {/*---------  Forecast -------------*/}
                <h3>Forecasting</h3>

                <div>
                  <br />
                  <br />
                  <button className="button" onClick={getDataSetClick}>
                    Get Dataset
                  </button>
                  <br />
                  <br />
                  {getDataSetState.loaded ? (
                    <iframe
                      title="html plot"
                      srcdoc={dataSet.html[0]}
                      width="750px"
                      height="270px"
                      style={{ border: 'none' }}
                    ></iframe>
                  ) : null}
                  <br />
                  <br />
                  <button className="button" onClick={forecastClick}>
                    Forecast
                  </button>
                  <br />
                  <br />
                  {forecast.forecasted ? (
                    <>
                      <iframe
                        title="html plot"
                        srcdoc={dataForecast.html[0]}
                        width="750px"
                        height="335px"
                        style={{ border: 'none' }}
                      ></iframe>
                      <iframe
                        title="html plot"
                        srcdoc={dataForecast.html[1]}
                        width="750px"
                        height="220px"
                        style={{ border: 'none' }}
                      ></iframe>
                      <iframe
                        title="html plot"
                        srcdoc={dataForecast.html[2]}
                        width="750px"
                        height="275px"
                        style={{ border: 'none' }}
                      ></iframe>
                      <iframe
                        title="html plot"
                        srcdoc={dataForecast.html[3]}
                        width="750px"
                        height="500px"
                        style={{ border: 'none' }}
                      ></iframe>
                    </>
                  ) : null}
                  <br />
                </div>
              </>
            ) : aiAnalyticsAction === 'segmentation' ? (
              <h3>segmentation</h3>
            ) : aiAnalyticsAction === 'churnAnalytics' ? (
              <h3>churn</h3>
            ) : aiAnalyticsAction === 'pricingAnalytics' ? (
              <h3>pricing</h3>
            ) : null
          ) : null}
        </div>{' '}
        {/*close split right container */}
      </div>{' '}
      {/*close split wrapper*/}
    </>
  )
}

export default Analytics
